.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 700px;
}



@media (max-width: 768px) {
  .swiper-slide {
    width: 250px;
    height: auto;
  }
}

.container {
  position: relative;
  width: 100%;
  /* height: 60vh; */
  height: auto;
  /* background-color: #000; */
  display: block;
}

.container .swiper-container {
  width: 70%;
  position: absolute;
  left: 50%;
  bottom: -16vw;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 2;
  padding-bottom: 3vw;
}

@media (max-width: 768px) {
  .container .swiper-container {
    bottom: -58vw;
    padding-bottom: 8vw;
  }
}

.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 5px;
  border-radius: 5px;
  background-color: white;
}