

  
  
  .main-footer {
    background-color: #f0f0f0;
    padding: 20px 0;
    width: 100%;
   
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    min-height: calc(100vh - 720px); /* Ajusta el valor 100px al tamaño de tu footer */
  }
  
  .footer-column {
    flex: 1;
    padding: 0 100px;
  }
  
  .footer-column h4 {
    margin-top: 0;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
  }

  h4 {
    font-size: 1.2em; /* Puedes ajustar este valor según lo necesites */
  }