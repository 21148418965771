/* Historia.css */



  .historia-content {
    /*background-image: url("../../images/fondoazul7.jpg");*/
    background-repeat: no-repeat;
    background-size: cover; /* Ajusta la imagen de fondo para cubrir todo el contenedor */
    padding: 20px;
    text-align: center;
  }
  
  .titulo {
    font-family: 'Roboto', sans-serif;
    font-size: 43px;
    font-weight: 600;
    color: #0f2379;
    /* Otros estilos para el título */
  }
  
  .contenido {
    text-align: center;
    padding: 1%;
  }
  
  .texto {
    background-color: #fafafa;
    padding: 1.5%;
    border-radius: 10px;
   
    font-family: "mt-4 text-black font-montserrat text-lg";
    max-width: 900px; /* Ancho máximo del cuadro de contenido */
    margin: 0 auto; /* Centrar el cuadro de contenido horizontalmente */
    font-size: 20px;
    text-align: left; /* Alineación del texto a la izquierda dentro del cuadro de contenido */
  }

  .texto p {
    margin-bottom: 15px; /* Espacio entre párrafos */
  
  }


.logo{
    display: block;
  margin: 0 auto 0px; /* Centrar la imagen horizontalmente y agregar espacio abajo */
  padding: 30px; /* Agregar espacio alrededor de la imagen */

}